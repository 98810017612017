<div class="chat-button">
    <button class="chat-toggle" (click)="toggleChat()">
        <img
            class="ct-icon"
            [class.close]="!isChatOpen"
            [class.open]="isChatOpen"
            [src]="isChatOpen ? '/assets/images/close.svg' : '/assets/images/chat-support.svg'"
            alt=""
        >
    </button>

    <div class="chat-popup" [class.active]="isChatOpen">
        <the-chat *ngIf="chatId" class="the-chat" [chatId]="chatId" [clientData]=""></the-chat>
        <chat-welcome *ngIf="!chatId" class="chat-welcome"></chat-welcome>
    </div>
</div>
