<!-- <div class="glow-circle circle-top"></div> -->

<!-- head -->
<div class="home-head">
    <div class="content">
        <div class="hh-wrapper">
            <h1 class="hh-title">{{words['home']?.['head-title']?.[lang.slug]}}</h1>
        </div>
        <div class="hh-circle-wrapper">
            <div class="hc-content">
                <h1 class="hh-title-content" [innerHTML]="words['home']?.['head-title-content']?.[lang.slug]"></h1>
                <h2 class="hh-subtitle-content">{{words['home']?.['suptitle']?.[lang.slug]}}</h2>
                <button class="btn hh-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn1']?.[lang.slug]}}</span></button>
            </div>
            <div class="hh-circle"></div>
        </div>
        <div class="glow-circle circle-bottom"></div>
    </div>
    <img class="coin-icon coin1" src="/assets/images/coin/coin1.png" />
    <img class="coin-icon coin2" src="/assets/images/coin/coin2.png" />
    <img class="coin-icon coin3" src="/assets/images/coin/coin3.png" />
    <img class="coin-icon coin4" src="/assets/images/coin/coin4.png" />
    <img class="coin-icon coin5" src="/assets/images/coin/coin5.png" />
</div>
<div class="content">
    <!-- advantages -->
    <section>
        <div class="home-advantages">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
            </div>
            <div class="ha-content">
                <div class="ha-top">
                    <div class="ha-item">
                        <div class="hi-number">01.</div>
                        <div class="hi-content">
                            <div class="hi-title">{{words['home']?.['advantages-title1']?.[lang.slug]}}</div>
                            <div class="hi-text">{{words['home']?.['advantages-text1']?.[lang.slug]}}</div>
                        </div>
                    </div>
                    <div class="ha-item">
                        <div class="hi-number">04.</div>
                        <div class="hi-content">
                            <div class="hi-title">{{words['home']?.['advantages-title4']?.[lang.slug]}}</div>
                            <div class="hi-text">{{words['home']?.['advantages-text4']?.[lang.slug]}}</div>
                        </div>
                    </div>
                </div>
                <div class="ha-bottom">
                    <div class="ha-item">
                        <div class="hi-number">02.</div>
                        <div class="hi-content">
                            <div class="hi-title">{{words['home']?.['advantages-title2']?.[lang.slug]}}</div>
                            <div class="hi-text">{{words['home']?.['advantages-text2']?.[lang.slug]}}</div>
                        </div>
                    </div>
                    <div class="ha-item">
                        <div class="hi-number">03.</div>
                        <div class="hi-content">
                            <div class="hi-title">{{words['home']?.['advantages-title3']?.[lang.slug]}}</div>
                            <div class="hi-text">{{words['home']?.['advantages-text3']?.[lang.slug]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- about -->
    <section>
        <div class="home-about">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
            </div>
            <div class="ha-top">
                <h2 class="ha-title" [innerHTML]="words['home']?.['about-title']?.[lang.slug]"></h2>
                <p class="ha-text">{{words['home']?.['about-text']?.[lang.slug]}}</p>
            </div>
            <div class="ha-body">
                <div class="hab-item">
                    <div class="bb-icon-mobile">
                        <img src="/assets/images/home-about1.svg" />
                    </div>
                    <div class="bi-title">{{words['home']?.['about-box-title1']?.[lang.slug]}}</div>
                    <div class="bi-body">
                        <div class="bb-text">{{words['home']?.['about-box-text1']?.[lang.slug]}}</div>
                        <div class="bb-icon-desktop">
                            <img src="/assets/images/home-about1.svg" />
                        </div>
                    </div>
                </div>
                <div class="hab-item">
                    <div class="bb-icon-mobile">
                        <img src="/assets/images/home-about2.svg" />
                    </div>
                    <div class="bi-title">{{words['home']?.['about-box-title2']?.[lang.slug]}}</div>
                    <div class="bi-body">
                        <div class="bb-text">{{words['home']?.['about-box-text2']?.[lang.slug]}}</div>
                        <div class="bb-icon-desktop">
                            <img src="/assets/images/home-about2.svg" />
                        </div>
                    </div>
                </div>
                <div class="hab-item">
                    <div class="bb-icon-mobile">
                        <img src="/assets/images/home-about3.svg" />
                    </div>
                    <div class="bi-title">{{words['home']?.['about-box-title3']?.[lang.slug]}}</div>
                    <div class="bi-body">
                        <div class="bb-text">{{words['home']?.['about-box-text3']?.[lang.slug]}}</div>
                        <div class="bb-icon-desktop">
                            <img src="/assets/images/home-about3.svg" />
                        </div>
                    </div>
                </div>
                <div class="hab-item">
                    <div class="bb-icon-mobile">
                        <img src="/assets/images/home-about4.svg" />
                    </div>
                    <div class="bi-title">{{words['home']?.['about-box-title4']?.[lang.slug]}}</div>
                    <div class="bi-body">
                        <div class="bb-text">{{words['home']?.['about-box-text4']?.[lang.slug]}}</div>
                        <div class="bb-icon-desktop">
                            <img src="/assets/images/home-about4.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<section class="home-numbers-section">
    <div class="hns-outline-circle c-left"></div>
    <div class="hns-outline-circle c-center"></div>
    <div class="hns-outline-circle c-right"></div>


    <div class="content">
        <div class="bg-ellipses">
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
            <div class="hns-ellipse"></div>
        </div>

        <div class="home-numbers">
            <div class="hn-items">
                <div class="hni-item">
                    <div class="hni-top hni-label">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                    <div class="hni-mid">{{words['home']?.['more-than1']?.[lang.slug]}}</div>
                    <div class="hni-bottom hni-label">{{words['home']?.['more-than-sup1']?.[lang.slug]}}</div>
                </div>
                <div class="hni-item">
                    <div class="hni-top hni-label">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                    <div class="hni-mid">{{words['home']?.['more-than2']?.[lang.slug]}}</div>
                    <div class="hni-bottom hni-label">{{words['home']?.['more-than-sup2']?.[lang.slug]}}</div>
                </div>
            </div>
            <div class="hn-center">{{words['home']?.['calc-title1']?.[lang.slug]}}</div>
            <div class="hn-items">
                <div class="hni-item">
                    <div class="hni-top hni-label">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                    <div class="hni-mid">{{words['home']?.['more-than3']?.[lang.slug]}}</div>
                    <div class="hni-bottom hni-label">{{words['home']?.['more-than-sup3']?.[lang.slug]}}</div>
                </div>
                <div class="hni-item">
                    <div class="hni-top hni-label">{{words['home']?.['more-than-top']?.[lang.slug]}}</div>
                    <div class="hni-mid">{{words['home']?.['more-than4']?.[lang.slug]}}</div>
                    <div class="hni-bottom hni-label">{{words['home']?.['more-than-sup4']?.[lang.slug]}}</div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- calculator -->
<section>
    <div class="content">
        <div class="home-calc">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
            </div>
            <div class="hc-bottom" name="calculator">
                <div class="hc-left">
                    <img class="coin-icon coin1" src="/assets/images/coin/coin1.png" />
                    <!-- <img class="coin-icon coin2" src="/assets/images/coin/coin2.png" /> -->
                    <img class="coin-icon coin3" src="/assets/images/coin/coin3.png" />
                    <img class="coin-icon coin4" src="/assets/images/coin/coin4.png" />
                    <img class="coin-icon coin5" src="/assets/images/coin/coin5.png" />

                    <the-calculator></the-calculator>

                    <div class="alert">
                        <img src="/assets/images/Danger Circle.svg" alt="">
                        <span>{{words['home']?.['hc-alert']?.[lang.slug]}}</span>
                    </div>
                    <button class="btn hc-btn-mob" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
                </div>
                <div class="hc-right">
                    <h2 class="hc-title1" [innerHTML]="words['home']?.['calc-title2']?.[lang.slug]"></h2>
                    <div class="hc-text">{{words['home']?.['calc-text']?.[lang.slug]}}</div>
                    <button class="btn hc-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
                </div>
            </div>
        </div>
    </div>
</section>

<section name="payment">
    <div class="content">
        <div class="payment">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
          </div>

          <div class="p-card">
              <div class="pc-left">
                  <div class="pc-title">{{words['home']?.['check-payment']?.[lang.slug]}}</div>
                  <div class="pc-text">{{words['home']?.['enter-payment-number']?.[lang.slug]}}</div>
              </div>
              <div class="pc-right">
                  <form class="gf" (submit)="checkPayment()">
                      <div class="gf-inputwrap">
                        <input
                            type="number"
                            name="paymentId"
                            [placeholder]="words['home']?.['payment-input-ph']?.[lang.slug]"
                            [(ngModel)]="paymentId"
                            [class.error]="error"
                        />
                    </div>
                      <div class="gf-btnwrap">
                          <button type="submit" class="btn" [class.busy]="false">
                              <span>{{words['home']?.['find-payment']?.[lang.slug]}}</span>
                          </button>
                      </div>
                  </form>
              </div>
            </div>
        </div>
    </div>
</section>

<!-- guide -->
<section>
    <div class="content">
        <div class="home-guide">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
            </div>
            <h2 class="hg-title" [innerHTML]="words['home']?.['guide-title']?.[lang.slug]"></h2>
            <div class="hg-steps">
                <div class="hg-item">
                    <div class="hg-top">
                        <div class="hg-number">01.</div>
                        <div class="hg-head">{{words['home']?.['guide-item-title1']?.[lang.slug]}}</div>
                    </div>
                    <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text1']?.[lang.slug]"></div>
                </div>
                <div class="hg-item">
                    <div class="hg-top">
                        <div class="hg-number">02.</div>
                        <div class="hg-head">{{words['home']?.['guide-item-title2']?.[lang.slug]}}</div>
                    </div>
                    <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text2']?.[lang.slug]"></div>
                </div>
                <div class="hg-item hg-wimg-item">
                    <img src="/assets/images/globe.svg" />
                </div>
                <div class="hg-item">
                    <div class="hg-top">
                        <div class="hg-number">03.</div>
                        <div class="hg-head">{{words['home']?.['guide-item-title3']?.[lang.slug]}}</div>
                    </div>
                    <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text3']?.[lang.slug]"></div>
                </div>
                <div class="hg-item">
                    <div class="hg-top">
                        <div class="hg-number">04.</div>
                        <div class="hg-head">{{words['home']?.['guide-item-title4']?.[lang.slug]}}</div>
                    </div>
                    <div class="hg-text" [innerHTML]="words['home']?.['guide-item-text4']?.[lang.slug]"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="content">
    <!-- converter -->
    <section name="crypto">
        <div class="lines">
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
        </div>
        <div class="home-converter">
            <div class="hc-left">
                <the-converter></the-converter>
                <div class="hc-items hc-items-mobile">
                    <div class="hc-item">
                        <div class="hi-left"><img src="/assets/images/btc.svg" /></div>
                        <div class="hi-right">{{words['home']?.['converter-value-btc']?.[lang.slug]}}</div>
                    </div>
                    <div class="hc-item">
                        <div class="hi-left"><img src="/assets/images/ltc.svg" /></div>
                        <div class="hi-right">{{words['home']?.['converter-value-ltc']?.[lang.slug]}}</div>
                    </div>
                </div>
            </div>
            <div class="hc-right">
                <div class="hc-texts">
                    <h2 class="hc-title">{{words['home']?.['converter-title']?.[lang.slug]}}</h2>
                    <div class="hc-text">{{words['home']?.['converter-suptitle']?.[lang.slug]}}</div>
                </div>
                <div class="hc-items hc-items-desktop">
                    <div class="hc-item">
                        <div class="hi-left"><img src="/assets/images/btc.svg" /></div>
                        <div class="hi-right">{{words['home']?.['converter-value-btc']?.[lang.slug]}}</div>
                    </div>
                    <div class="hc-item">
                        <div class="hi-left"><img src="/assets/images/ltc.svg" /></div>
                        <div class="hi-right">{{words['home']?.['converter-value-ltc']?.[lang.slug]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- account -->
<div class="content">
    <div class="lines">
        <div class="l-line"></div>
        <div class="l-line"></div>
        <div class="l-line"></div>
        <div class="l-line"></div>
    </div>
    <section class="account-section">
        <div class="home-account">
            <div class="ha-left">
                <h2 class="ha-title">{{words['home']?.['open-account']?.[lang.slug]}}</h2>
                <button class="btn ha-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
            </div>
            <div class="ha-right">
                <img src="/assets/images/wallet.png" />
            </div>
        </div>
    </section>
</div>
