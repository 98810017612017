import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { NotificationRepository } from "src/app/common/services/repositories/notification.repository";
import { PageRepository } from "src/app/common/services/repositories/page.repository";
import { INotification } from "src/app/model/entities/notification.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { SimplePage } from "src/app/pages/simple.page";

@Component({
    selector: "notifications-account-page",
    templateUrl: "notifications.account.page.html",
    styleUrls: ["notifications.account.page.scss"],
})
export class NotificationsAccountPage extends SimplePage implements OnInit, AfterViewInit, OnDestroy {
    public notifications: INotification[] = [];
    public notificationsReady: boolean = false;
    public sortBy: string = "created_at";
    public sortDir: number = -1;
    public loadingMore: boolean = false;
    public part: number = 0;
    public chunkLength: number = 20;
    public exhausted: boolean = false;
    private started_at: Date = null;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected pageRepository: PageRepository,
        protected notificationRepository: NotificationRepository,
        protected route: ActivatedRoute,
        protected router: Router,
    )
    {
        super(appService, pageRepository, route, router);
    }

    get user_id(): number {return this.authService.authData.id;}
    get scrolledToBottom(): boolean {return this.appService.win.scrollHeight - this.appService.win.scrollTop < this.appService.win.clientHeight + this.appService.footer.offsetHeight;}
    get canLoadMore(): boolean {return !this.loadingMore && !this.exhausted && this.scrolledToBottom;}

    public async ngOnInit(): Promise<void> {
        this.onScroll = this.onScroll.bind(this);
        this.initNotifications();
        await this.initPage('notifications');
        this.route.params.subscribe(p => this.initSEO());
    }

    public ngAfterViewInit(): void {
        this.appService.win.addEventListener("scroll", this.onScroll);
    }

    public ngOnDestroy(): void {
        this.appService.win.removeEventListener("scroll", this.onScroll);
    }

    private async initNotifications(): Promise<void> {
		try {
            const filter = {user_id: this.user_id};
            // Для предотвращения дублей в бесконечной прокрутке при добавлении новых элементов после момента, когда первый кусок загружен.
            // Если сразу вставить в фильтр created: this.started_at, в серверной версии была бы проблема из-за другой таймзоны.
            this.started_at = new Date();
            const chunk = await this.notificationRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);
            this.notifications = chunk.data;
            this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;
            this.notificationsReady = true;
		} catch (err) {
			this.appService.notifyError(err);
		}
	}

    public async onScroll(): Promise<void> {
        try {
            if (this.canLoadMore) {
				this.loadingMore = true;
				this.part++;
                const filter = {user_id: this.user_id, created_at: this.started_at};
                const chunk = await this.notificationRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);
                this.notifications = [...this.notifications, ...chunk.data];
                this.exhausted = !chunk.elementsQuantity || this.part + 1 === chunk.pagesQuantity;
				this.loadingMore = false;
			}
		} catch (err) {
			this.appService.notifyError(err);
            this.loadingMore = false;
		}
    }

    public twoDigits(n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }

    public formattedDate(datestring: string, withTime: boolean = false): string {
        const date = new Date(datestring + ' UTC');
        const time = withTime ? ` ${this.twoDigits(date.getHours())}:${this.twoDigits(date.getMinutes())}` : '';
        return date ? `${this.twoDigits(date.getDate())}.${this.twoDigits(date.getMonth()+1)}.${date.getFullYear()}${time}` : "";
    }
}
