import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DirectivesModule } from "../directives/directives.module";
import { PipesModule } from "../pipes/pipes.module";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MMAccountComponent } from "./mobile-menus/mm-account/mm-account.component";
import { MMMainComponent } from "./mobile-menus/mm-main/mm-main.component";
import { PanelLogoutComponent } from "./panels/logout/panel-logout.component";
import { PanelNotificationsComponent } from "./panels/notifications/panel-notifications.component";
import { PopupLoginComponent } from "./popups/login/popup-login.component";
import { PopupLogoutComponent } from "./popups/logout/popup-logout.component";
import { PopupReceiveBankComponent } from "./popups/receive/bank/popup-receive-bank.component";
import { PopupReceiveCryptoComponent } from "./popups/receive/crypto/popup-receive-crypto.component";
import { PopupRegisterComponent } from "./popups/register/popup-register.component";
import { PopupRegisteredComponent } from "./popups/registered/popup-registered.component";
import { PopupSendBankComponent } from "./popups/send/bank/popup-send-bank.component";
import { PopupSendCryptoComponent } from "./popups/send/crypto/popup-send-crypto.component";
import { PopupCreditComponent } from "./popups/credit/popup-credit.component";
import { CardComponent } from "./card/card.component";
import { PeriodComponent } from "./period/period.component";
import { PopupCreditedComponent } from "./popups/credited/popup-credited.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { SliderComponent } from "./slider/slider.component";
import { LangDropdown } from "./lang-dropdown/lang-dropdown.component";
import { PanelAccountComponent } from "./panels/account/panel-account.component";
import { PopupNotificationsComponent } from "./popups/notifications/popup-notifications.component";
import { ChatButtonComponent } from "./chat-button/chat-button.component";
import { ChatComponent } from "./chat/chat.component";
import { ChatWellcomeComponent } from "./chat-button/chat-welcome/chat-welcome.component";
import { MessageComponent } from "./message/message.component";
// import { ChatFileComponent } from "./chat-file/chat-file.component";
// import { ChatImgComponent } from "./chat-img/chat-img.component";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        NgxSliderModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PopupCreditComponent,
        PopupCreditedComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
		    CardComponent,
        PeriodComponent,
        CalculatorComponent,
        SliderComponent,
        LangDropdown,
        PanelAccountComponent,
        PopupNotificationsComponent,
        ChatButtonComponent,
        ChatComponent,
        ChatWellcomeComponent,
        MessageComponent,
        // ChatFileComponent,
        // ChatImgComponent,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PopupCreditComponent,
        PopupCreditedComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
        CardComponent,
        PeriodComponent,
        CalculatorComponent,
        SliderComponent,
        LangDropdown,
        PanelAccountComponent,
        PopupNotificationsComponent,
        ChatButtonComponent,
        ChatComponent,
        ChatWellcomeComponent,
        MessageComponent,
        // ChatFileComponent,
        // ChatImgComponent,
    ],
    providers: [],
})
export class ComponentsModule {}
