<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['notifications']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content">
            <ng-container *ngIf="!notifications.length">
                {{words['notifications']?.['empty']?.[lang.slug]}}
            </ng-container>
            <ng-container *ngIf="notifications.length">
                <div class="n-item" *ngFor="let n of notifications">
                    <div class="n-date">{{formattedDate(n.date[lang.slug], true)}}</div>
                    <div class="n-content" [innerHTML]="n.content[lang.slug]"></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
