import { Component } from "@angular/core";
import { MMComponent } from "../mm.component";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";

@Component({
    selector: "mm-account",
    templateUrl: "mm-account.component.html",
    styleUrls: [
        "../mm.component.scss",
        "mm-account.component.scss",
    ]
})
export class MMAccountComponent extends MMComponent {
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
    set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}
    set pLogoutActive(v: boolean) {this.appService.pLogoutActive = v;}

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    ) {
        super(appService, authService);
    }

    public activatePopupRegister(): void {
        this.pRegisterActive = true;
        this.close();
    }

    public activatePopupLogin(): void {
        this.pLoginActive = true;
        this.close();
    }

    public activatePopupLogout(): void {
        this.pLogoutActive = true;
        this.close();
    }

    public async onPaymentClick() {
        this.router.navigateByUrl(`/${this.lang.slug}`);
        await this.appService.pause(10); // wait for page initial autoscroll

        this.appService.scrollTo('payment');
    }
}
