<footer name="footer" #footer>
    <div class="f-top">
        <div class="content">
            <div class="ft-content">
                <div class="ft-contacts">
                    <div class="ftc-address">{{words['footer']?.['address']?.[lang.slug]}}</div>
                    <div class="ftc-phone">{{words['footer']?.['number']?.[lang.slug]}}</div>
                </div>

                <div class="ft-controls">
                    <div class="ftc-links">
                        <ul>
                            <li><a (click)="onMenuClick('about')">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                            <li><a (click)="onMenuClick('calculator')">{{words['header']?.['calculator']?.[lang.slug]}}</a></li>
                            <li><a (click)="onMenuClick('crypto')">{{words['header']?.['crypto']?.[lang.slug]}}</a></li>
                        </ul>
                    </div>
                    <lang-dropdown class="lang-dropdown-mobile"></lang-dropdown>
                    <div class="ftc-btns">
                        <button class="btn ftc-btn" (click)="onPaymentClick()"><span>{{words['footer']?.['check-payment']?.[lang.slug]}}</span></button>
                        <a
                            class="ftc-btn-telegram"
                            [href]="words['footer']?.['tg-link']?.[lang.slug]"
                            target="_blank"
                        >
                            {{words['footer']?.['tg-label']?.[lang.slug]}}
                        </a>
                    </div>
                </div>

                <lang-dropdown class="lang-dropdown-desktop"></lang-dropdown>
            </div>
            <div class="ft-title">
                <h1 class="ftt-title">{{words['home']?.['head-title']?.[lang.slug]}}</h1>
            </div>
        </div>
    </div>
    <div class="f-bottom">
        <div class="content">
            <div class="fb-content">
                <div class="f-label">{{words['footer']?.['copyright']?.[lang.slug]}}</div>
                <a class="f-label" href="{{staticUrl}}/ufiles/{{ufiles?.['fees'].fileurl}}" target="_blank">{{words['footer']?.['privacy']?.[lang.slug]}}</a>
                <a class="f-label" href="{{staticUrl}}/ufiles/{{ufiles?.['terms'].fileurl}}" target="_blank">{{words['footer']?.['policy']?.[lang.slug]}}</a>
            </div>
        </div>
    </div>
</footer>
