<div class="chat">
    <div class="chat-head">
        <button class="ch-back-btn">
            <img class="ch-back-icon" src="/assets/images/back.svg" alt="" (click)="onBackClick()">
        </button>
        <div class="ch-right">
            <img class="ch-profile-icon" src="/assets/images/profile.svg" alt="">
            <div class="ch-content">
                <div class="chc-title">{{words['chat']?.['support']?.[lang.slug]}}</div>
                <div class="chc-status">{{words['chat']?.['online']?.[lang.slug]}}</div>
            </div>
        </div>
    </div>

    <div class="chat-main" #mlelement (scroll)="onScroll()">
        <div class="msg-placeholder" *ngIf="!ml.length">{{words['chat']?.['ask-question']?.[lang.slug]}}</div>
        <div class="chat-msg" *ngFor="let message of ml">
            <the-message [x]="message"></the-message>
        </div>
        <div class="loading" *ngIf="!mlReady || mlLoadingMore"><span></span></div>
    </div>

    <div class="chat-foot">
       <!-- <div class="roundbtn material attach"><span></span></div> -->
        <div class="chat-inputwrap">
            <input
                name="message"
                type="text"
                class="c-message-input"
                [placeholder]="words['chat']?.['enter-new-message']?.[lang.slug]"
                [(ngModel)]="myMessage"
                (keydown)="onMessageKeyDown($event)"
            />
        </div>
        <button class="send-btn msg" (click)="sendMessage()">
            <span>
                <img class="send-icon" src="/assets/images/send.svg" alt="">
            </span>
        </button>
    </div>
</div>
