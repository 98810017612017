export abstract class Entity {
    public id: number;
    public defended: boolean;
    public __selected: boolean = false;

    public build (o: Object): any {
        for (let field in o) {
            this[field] = o[field];
        }

        return this;
    }

    protected twoDigits(n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }

    public formattedDate(field, useTime: boolean = false): string {
        const date = this[field] as Date;

        if (!date) {
            return "";
        }

        const time = useTime ? ` ${this.twoDigits(date.getHours())}:${this.twoDigits(date.getMinutes())}` : "";
        return `${this.twoDigits(date.getDate())}.${this.twoDigits(date.getMonth()+1)}.${date.getFullYear()}${time}`;
    }
}
