<div class="chat-welcome">
    <div class="cw-head">
        <h2 class="cw-title">{{words['chat']?.['hello']?.[lang.slug]}}</h2>
        <p class="cw-hello-text">{{words['chat']?.['welcome']?.[lang.slug]}}</p>
    </div>

    <div class="cw-bottom">
        <div class="cw-start cw-box">
            <div class="cw-title">{{words['chat']?.['lets-start-chat']?.[lang.slug]}}</div>
            <form class="cws-form" (submit)="onSubmit()">
                <div class="cws-input-wrapper">
                    <input class="cws-name-input" type="text" name="name" [(ngModel)]="name" [placeholder]="words['chat']?.['enter-name']?.[lang.slug]" />
                    <div *ngIf="errors['name']" class="cws-error">{{words['chat']?.['name-required-error']?.[lang.slug]}}</div>
                </div>
                <button type="submit" class="btn"><span>{{words['chat']?.['start-chat']?.[lang.slug]}}</span></button>
            </form>
        </div>

        <div class="cw-foot cw-box">
            <div class="cw-title">{{words['chat']?.['another-contacts']?.[lang.slug]}}</div>
            <a
                class="cwf-btn-telegram"
                [href]="words['footer']?.['tg-link']?.[lang.slug]"
                target="_blank"
            >
                {{words['footer']?.['tg-label']?.[lang.slug]}}
            </a>
        </div>
    </div>
</div>
