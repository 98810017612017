<div class="mm" [class.active]="active">
    <div class="mm-content">
        <nav class="mm-menu">
            <ul>
                <ng-container *ngIf="authenticated">
                    <li>
                        <img src="/assets/images/ion_card.svg" alt="">
                        <a routerLink="/{{lang.slug}}/account" [class.active]="url[2] === 'account' && !url[3]" (click)="close()">{{words['header']?.['my-cards']?.[lang.slug]}}</a>
                    </li>
                    <li>
                        <img src="/assets/images/ion_notifications.svg" alt="">
                        <a routerLink="/{{lang.slug}}/account/notifications" [class.active]="url[2] === 'account' && url[3] === 'notifications'" (click)="close()">{{words['header']?.['notifications']?.[lang.slug]}}</a>
                    </li>
                    <li>
                        <img src="/assets/images/ion_logout.svg" alt="">
                        <a class="danger" (click)="activatePopupLogout()">{{words['header']?.['logout']?.[lang.slug]}}</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="!authenticated">
                    <li><a (click)="activatePopupLogin()">{{words['header']?.['login']?.[lang.slug]}}</a></li>
                    <li><a (click)="activatePopupRegister()">{{words['header']?.['register']?.[lang.slug]}}</a></li>
                </ng-container>
            </ul>
        </nav>
        <div class="mm-bottom">
            <lang-dropdown></lang-dropdown>
            <button class="btn ha-btn" (click)="onPaymentClick()"><span>{{words['header']?.['check-payment']?.[lang.slug]}}</span></button>
        </div>
    </div>
</div>
