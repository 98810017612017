import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { PageRepository } from "src/app/common/services/repositories/page.repository";
import { PaymentRepository } from "src/app/common/services/repositories/payment.repository";
import { cfg } from "src/app/config";
import { IPayment } from "src/app/model/entities/payment.interface";
import { SimplePage } from "src/app/pages/simple.page";

@Component({
    selector: "payment-page",
    templateUrl: "payment.page.html",
    styleUrls: ["payment.page.scss"],
})
export class PaymentPage extends SimplePage implements OnInit {
    public payment: IPayment;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected paymentRepository: PaymentRepository,
        protected pageRepository: PageRepository,
        protected route: ActivatedRoute,
        protected router: Router,
    )
    {
        super(appService, pageRepository, route, router);
    }

    get paymentId(): number {return parseInt(this.route.snapshot.params["id"]);}
    get staticUrl(): string {return cfg.staticUrl;}

    public getImage(path: string): string {
        return `${this.staticUrl}/images/payments/${path}`;
    }

    public async ngOnInit(): Promise<void> {
		    try {
            await this.initPage('payment');
            this.route.params.subscribe(_ => this.initSEO());
            this.payment = await this.paymentRepository.loadOne(this.paymentId);
		    } catch (err) {
            this.appService.notifyError(err);
			      this.router.navigateByUrl(`/${this.appService.lang.slug}/errors/404`);
		    }
    }

    public backToHome() {
			  this.router.navigateByUrl('/');
    }
}
