import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/services/guards/auth.guard';
import { AccountModule } from './pages/account/account.module';
import { ErrorsModule } from './pages/errors/errors.module';
import { HomePage } from './pages/home/pages/home.page';
import { PaymentPage } from './pages/payment/pages/payment.page';
import { StaticModule } from './pages/static/static.module';

const routes: Routes = [
    {path: "", component: HomePage, data: {mark: "home"}}, // mark for reuse
    {path: "ru", pathMatch: "full", redirectTo: "/"},
    {path: ":lang", component: HomePage, data: {mark: "home"}},  // mark for reuse
    {path: ":lang/payment/:id", component: PaymentPage},
    {path: ":lang/errors", loadChildren: () => ErrorsModule},
    {path: ":lang/account", loadChildren: () => AccountModule, canActivate: [AuthGuard]},
    {path: ":lang/:page", loadChildren: () => StaticModule},
    {path: "**", redirectTo: "/ru/errors/404"}
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                initialNavigation: 'enabledBlocking',
            },
        )
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
