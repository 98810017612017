import { Injectable } from "@angular/core";
import { Chunk } from "src/app/model/dto/chunk";
import { IChatCreate } from "src/app/model/dto/chat.create.interface";
import { IGetList } from "src/app/model/dto/getlist.interface";
import { Chat } from "src/app/model/entities/chat.entity";
// import { TotalUnreadCount } from "src/app/model/entities/totalUnreadCount.type";
import { DataService } from "../data.service";

@Injectable()
export class ChatRepository {
    constructor(protected dataService: DataService) {}

    public loadChunk(part: number = 0, chunkLength: number = 20, sortBy: string = "id", sortDir: number = -1, filter: any = {}): Promise<Chunk<Chat>> {
        const dto: IGetList = {from: part * chunkLength, q: chunkLength, sortBy, sortDir, filter};
        return new Promise((resolve, reject) =>
            this.dataService
                .chatsChunk(dto)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(new Chunk(res.data.map(d => new Chat().build(d)), res.elementsQuantity, res.pagesQuantity)) : reject(res.error),
                    error: err => reject(err.message)
                }));
    }

    public loadOne(id: number): Promise<Chat> {
        return new Promise((resolve, reject) => this.dataService
            .chatsOne(id)
            .subscribe({
                next: res => res.statusCode === 200 ? resolve(new Chat().build(res.data)) : reject(res.error),
                error: err => reject(err.message)
            }));
    }

    public create(dto: IChatCreate): Promise<Chat> {
        return new Promise((resolve, reject) => this.dataService
            .chatsCreate(dto)
            .subscribe({
                next: res => res.statusCode === 201 ? resolve(new Chat().build(res.data)) : reject(res.error),
                error: err => reject(err.message)
            }));
    }

    public delete(id: number): Promise<void> {
        return new Promise((resolve, reject) =>
            this.dataService
                .chatsDelete(id)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve() : reject(res.error),
                    error: err => reject(err.message),
                }));
    }

    // public totalUnreadCount(userId: number): Promise<TotalUnreadCount> {
    //     return new Promise((resolve, reject) => this.dataService
    //         .chatsTotalUnreadCount(userId)
    //         .subscribe({
    //             next: res => res.statusCode === 200 ? resolve(res.data) : reject(res.error),
    //             error: err => reject(err.message)
    //         }));
    // }
}
