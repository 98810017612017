import { Component, OnInit } from "@angular/core";
import { PopupComponent } from "../popup.component";
import { AppService } from "src/app/common/services/app.service";
import { INotification } from "src/app/model/entities/notification.interface";
import { NotificationRepository } from "src/app/common/services/repositories/notification.repository";
import { AuthService } from "src/app/common/services/auth.service";

@Component({
    selector: "popup-notifications",
    templateUrl: "popup-notifications.component.html",
    styleUrls: [
        "../../../styles/forms.scss",
        "../popup.component.scss",
        "popup-notifications.component.scss",
    ],
})
export class PopupNotificationsComponent extends PopupComponent implements OnInit {
    public notifications: INotification[] = [];
    public sortBy: string = "created_at";
    public sortDir: number = -1;
    public part: number = 0;
    public chunkLength: number = 20;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected notificationRepository: NotificationRepository,
    )
    {
        super(appService);
    }

    get user_id(): number {return this.authService.authData.id;}

    public async ngOnInit(): Promise<void> {
        await this.initNotifications();
    }

    private async initNotifications(): Promise<void> {
		    try {
            const filter = {user_id: this.user_id};
            const chunk = await this.notificationRepository.loadChunk(this.part, this.chunkLength, this.sortBy, this.sortDir, filter);
            this.notifications = chunk.data;
		    } catch (err) {
			      this.appService.notifyError(err);
		    }
	  }

    public twoDigits(n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }

    public formattedDate(datestring: string, withTime: boolean = false): string {
        const date = new Date(datestring + ' UTC');
        const time = withTime ? ` ${this.twoDigits(date.getHours())}:${this.twoDigits(date.getMinutes())}` : '';
        return date ? `${this.twoDigits(date.getDate())}.${this.twoDigits(date.getMonth()+1)}.${date.getFullYear()}${time}` : "";
    }
}
