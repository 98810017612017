import { Component } from "@angular/core";
import { PanelComponent } from "../panel.component";

@Component({
    selector: "panel-account",
    templateUrl: "panel-account.component.html",
    styleUrls: [
        "../panel.component.scss",
        "panel-account.component.scss",
    ],
})
export class PanelAccountComponent extends PanelComponent {
    set pLogoutActive(v: boolean) {this.appService.pLogoutActive = v;}
    set paNotificationsActive(v: boolean) {this.appService.paNotificationsActive = v;}

    public activatePopupNotifications(): void {
        this.paNotificationsActive = true;
        this.onClose();
    }

    public activatePopupLogout(): void {
        this.pLogoutActive = true;
        this.onClose();
    }
}
