<div class="panel-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="panel" [class.active]="active">
    <div class="account">
        <a class="a-row" routerLink="/{{lang.slug}}/account" (click)="onClose()">
            <img class="ar-img" src="/assets/images/ion_card.svg" alt="">
            <div class="ar-text">{{words['header']?.['my-cards']?.[lang.slug]}}</div>
        </a>

        <div class="a-divider"></div>

        <a class="a-row" (click)="activatePopupNotifications()">
            <img class="ar-img" src="/assets/images/ion_notifications.svg" alt="">
            <div class="ar-text">{{words['header']?.['notifications']?.[lang.slug]}}</div>
        </a>

        <div class="a-divider"></div>

        <a class="a-row" (click)="activatePopupLogout()">
            <img class="ar-img" src="/assets/images/ion_logout.svg" alt="">
            <div class="ar-text">{{words['header']?.['logout']?.[lang.slug]}}</div>
        </a>
    </div>
</div>
