import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { ChatService } from "src/app/common/services/chat.service";
import { CookieService } from "src/app/common/services/cookie.service";
import { ChatRepository } from "src/app/common/services/repositories/chat.repository";
import { Chat } from "src/app/model/entities/chat.entity";
import { ILang } from "src/app/model/entities/lang.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";

@Component({
    selector: "chat-welcome",
    templateUrl: "chat-welcome.component.html",
    styleUrls: [
        "chat-welcome.component.scss",
        "../../../../common/styles/forms.scss",
    ],
})
export class ChatWellcomeComponent implements OnChanges {
    public name: string = "";
    public errors: IKeyValue<string> = {};
    public errorDenied: boolean = false;
    public loading: boolean = false;

    constructor(
        private appService: AppService,
        private chatService: ChatService,
    ) {}

    get lang(): ILang {return this.appService.lang;}
    get words(): IWords {return this.appService.words;}

    public ngOnChanges(changes: SimpleChanges): void {
        // if (!this.active) {
        //     this.errors = {};
        // }
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            const clientData = this.chatService.doSimpleAuth(this.name);
            await this.chatService.startChat(clientData);
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    private validate(): boolean {
        let error = false;

        if (!this.name) {
            this.errors["name"] = "required";
            error = true;
        } else {
            this.errors["name"] = null;
        }

        return !error;
    }
}
