import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { CookieService } from './cookie.service';
import { DataService } from './data.service';
import { AuthGuard } from './guards/auth.guard';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { CardRepository } from './repositories/card.repository';
import { LangRepository } from './repositories/lang.repository';
import { NotificationRepository } from './repositories/notification.repository';
import { PageRepository } from './repositories/page.repository';
import { PaysystemRepository } from './repositories/paysystem.repository';
import { SettingRepository } from './repositories/setting.repository';
import { TransactionRepository } from './repositories/transaction.repository';
import { UfileRepository } from './repositories/ufile.repository';
import { WordRepository } from './repositories/word.repository';
import { CreditRepository } from './repositories/credit.repository';
import { PaymentRepository } from './repositories/payment.repository';
import { ChatRepository } from './repositories/chat.repository';
import { MessageRepository } from './repositories/message.repository';
import { ChatService } from './chat.service';
import { SoundService } from './sound.service';
import { SocketService } from './socket.service';

@NgModule({
    declarations: [],
    exports: [],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        AppService,
        DataService,
        CookieService,
        AuthService,
        ChatService,
        SoundService,
        SocketService,
        AuthGuard,
        SettingRepository,
        LangRepository,
        WordRepository,
        UfileRepository,
        PageRepository,
        CardRepository,
        TransactionRepository,
        NotificationRepository,
        PaysystemRepository,
        CreditRepository,
        PaymentRepository,
        ChatRepository,
        MessageRepository,
    ],
})
export class ServicesModule {}
