import { Injectable } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { cfg } from "src/app/config";

@Injectable()
export class SocketService {
    public connect(path: string, query?: any): Socket {
        const socket = io(cfg.serverUrl, {path, query});
        // const socket = io('http://localhost:4130', {path, query});
        this.initEvents(socket);
        return socket;
    }

    public disconnect(socket: Socket): void {
        socket?.disconnect();
        socket?.off(); // unsubscribe from all events
    }

    private initEvents(socket: Socket): void {
        socket.on("connect", () => console.log("socket connected"));
        socket.on("disconnect", () => console.log("socket disconnected"));
        socket.on("connect_error", () => console.log("connection error"));
        socket.on("connect_timeout", () => console.log("connection error"));
    }
}
