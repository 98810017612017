<div class="converter-wrapper">
    <img class="c-bubble c-bubble1" src="/assets/images/buuble_calc_1.png" />
    <img class="c-bubble c-bubble2" src="/assets/images/buuble_calc_2.png" />

    <div class="converter">
        <div class="c-tabs">
            <button (click)="changeCurrency(0)" class="c-tab" [class.c-active]="activeTab === 0"><span>{{words['home']?.['converter-ltc']?.[lang.slug]}}</span></button>
            <button (click)="changeCurrency(1)" class="c-tab" [class.c-active]="activeTab === 1"><span>{{words['home']?.['converter-btc']?.[lang.slug]}}</span></button>
        </div>
        <div class="c-inputs">
            <div class="c-input">
                <div class="input-title">{{words['home']?.['give']?.[lang.slug]}}</div>
                <div class="input-body">
                    <input type="number" name="value" [ngModel]="usdValue" (ngModelChange)="usdValueChange($event)">
                    <span class="c-currency">{{activeTab === 0 ? words['home']?.['ltc-symbol']?.[lang.slug] : words['home']?.['btc-symbol']?.[lang.slug]}}</span>
                </div>
            </div>
            <div class="c-input">
                <div class="input-title">{{words['home']?.['recieve']?.[lang.slug]}}</div>
                <div class="input-body">
                    <input type="number" name="value" disabled [value]="cryptoValue">
                    <span class="c-currency">{{words['home']?.['usd-symbol']?.[lang.slug]}}</span>
                </div>
            </div>
        </div>
        <button class="btn c-btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-button']?.[lang.slug]}}</span></button>
    </div>
</div>
