<div class="payment">
    <section class="content">
        <div class="payment-details">
            <div class="lines">
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
                <div class="l-line"></div>
            </div>

            <div class="p-head foreground">
                <img class="ph-swift-logo" src="/assets/images/swift.png" />
                <div class="ph-title">{{words['payment']?.['payment-title']?.[lang.slug]}}</div>
                <div class="ph-id">№ {{payment.id}}</div>
            </div>

            <div class="p-data foreground">
                <img class="pd-bubble pd-bubble1" src="/assets/images/payment-bubbles-1.png" />
                <img class="pd-bubble pd-bubble2" src="/assets/images/payment-bubbles-2.png" />

                <div class="pd-card pd-sender">
                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['sender']?.[lang.slug]}}</div>
                        <div class="pdc-value">
                            <img [src]="getImage(payment.sender_img)" alt="">
                        </div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['sender-bank']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.sender_bank}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['sender-account']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.sender_account}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['description']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.description}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['status']?.[lang.slug]}}</div>
                        <div class="pdc-value status-{{payment.status}}">{{words['payment']?.['status-'+payment.status]?.[lang.slug]}}</div>
                    </div>

                    <div class="pdc-transfer-info">{{words['payment']?.['transfer-info']?.[lang.slug]}}</div>
                </div>

                <div class="pd-card pd-recipient">
                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['recipient']?.[lang.slug]}}</div>
                            <img [src]="getImage(payment.recipient_img)" alt="">
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['recipient-bank']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.recipient_bank}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['recipient-account']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.recipient_account}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['bic']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.bic}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['phone']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.phone}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['amount']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.amount}}</div>
                    </div>

                    <div class="pdc-row">
                        <div class="pdc-key">{{words['payment']?.['recipient-name']?.[lang.slug]}}</div>
                        <div class="pdc-value">{{payment.recipient_name}}</div>
                    </div>
                </div>
            </div>

            <div class="p-btns">
                <button class="btn pb-btn" (click)="backToHome()"><span>{{words['payment']?.['button-text']?.[lang.slug]}}</span></button>

                <!-- TODO: use anchor below instead of button above -->
                <!-- <div class="popup-btn"><a class="btn" routerLink="/{{lang.slug}}">{{words['register']?.['home']?.[lang.slug]}}</a></div> -->
            </div>
        </div>
    </section>

    <section class="payment-advantages">
        <div class="pa-wrapper" >
            <div class="pa-line"></div>
            <div class="content">
                <div class="bg-ellipses">
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                    <div class="hns-ellipse"></div>
                </div>

                <div class="pa-items pai-items-first">
                    <div class="pai-item">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-1.svg" />
                            {{words['payment']?.['advantages-item-title1']?.[lang.slug]}}
                        </div>

                        <div class="pai-bottom">
                            <div class="pai-text">{{words['payment']?.['advantages-item-text1']?.[lang.slug]}}</div>
                            <div class="pai-imgs">
                                <img src="/assets/images/advantages-methods/1.svg" />
                                <img src="/assets/images/advantages-methods/2.svg" />
                                <img src="/assets/images/advantages-methods/3.svg" />
                                <img src="/assets/images/advantages-methods/4.svg" />
                                <img src="/assets/images/advantages-methods/5.svg" />
                            </div>
                        </div>
                    </div>

                    <div class="pai-item pai-item-second">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-2.svg" />
                            {{words['payment']?.['advantages-item-title2']?.[lang.slug]}}
                        </div>

                        <div class="pai-bottom">
                            <div class="pai-text">{{words['payment']?.['advantages-item-text2']?.[lang.slug]}}</div>
                            <div class="pai-imgs">
                                <img src="/assets/images/advantages-methods/6.svg" />
                            </div>
                        </div>
                    </div>

                    <div class="pai-item">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-3.svg" />
                            {{words['payment']?.['advantages-item-title3']?.[lang.slug]}}
                        </div>

                        <div class="pai-text">{{words['payment']?.['advantages-item-text3']?.[lang.slug]}}</div>
                    </div>
                </div>

                <!--  -->

                <div class="pa-items">
                    <div class="pai-item">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-1.svg" />
                            {{words['payment']?.['advantages-item-title1']?.['en']}}
                        </div>

                        <div class="pai-bottom">
                            <div class="pai-text">{{words['payment']?.['advantages-item-text1']?.['en']}}</div>
                            <div class="pai-imgs">
                                <img src="/assets/images/advantages-methods/1.svg" />
                                <img src="/assets/images/advantages-methods/2.svg" />
                                <img src="/assets/images/advantages-methods/3.svg" />
                                <img src="/assets/images/advantages-methods/4.svg" />
                                <img src="/assets/images/advantages-methods/5.svg" />
                            </div>
                        </div>
                    </div>

                    <div class="pai-item pai-item-second">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-2.svg" />
                            {{words['payment']?.['advantages-item-title2']?.['en']}}
                        </div>

                        <div class="pai-bottom">
                            <div class="pai-text">{{words['payment']?.['advantages-item-text2']?.['en']}}</div>
                            <div class="pai-imgs">
                                <img src="/assets/images/advantages-methods/6.svg" />
                            </div>
                        </div>
                    </div>

                    <div class="pai-item">
                        <div class="pai-top">
                            <img src="/assets/images/advantages-item-3.svg" />
                            {{words['payment']?.['advantages-item-title3']?.['en']}}
                        </div>

                        <div class="pai-text">{{words['payment']?.['advantages-item-text3']?.['en']}}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="lines">
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
        </div>

        <div class="partners foreground">
            <h2 class="p-title">{{words['payment']?.['partners-title']?.[lang.slug]}}</h2>

            <div class="p-grid">
                <div class="pg-row">
                    <div class="pg-logo">
                        <img src="/assets/images/partners/1.png" alt="">
                    </div>

                    <div class="pg-content">
                        <div class="pgc-sup">{{words['payment']?.['partner-sup']?.[lang.slug]}}</div>
                        <div class="pgc-title">{{words['payment']?.['partner-title-1']?.[lang.slug]}}</div>
                        <div class="pgc-text">{{words['payment']?.['partner-text-1']?.[lang.slug]}}</div>
                    </div>
                </div>

                <div class="pg-row">
                    <div class="pg-logo">
                        <img src="/assets/images/partners/2.png" alt="">
                    </div>

                    <div class="pg-content">
                        <div class="pgc-sup">{{words['payment']?.['partner-sup']?.[lang.slug]}}</div>
                        <div class="pgc-title">{{words['payment']?.['partner-title-2']?.[lang.slug]}}</div>
                        <div class="pgc-text">{{words['payment']?.['partner-text-2']?.[lang.slug]}}</div>
                    </div>
                </div>

                <div class="pg-row">
                    <div class="pg-logo">
                        <img src="/assets/images/partners/3.png" alt="">
                    </div>

                    <div class="pg-content">
                        <div class="pgc-sup">{{words['payment']?.['partner-sup']?.[lang.slug]}}</div>
                        <div class="pgc-title">{{words['payment']?.['partner-title-3']?.[lang.slug]}}</div>
                        <div class="pgc-text">{{words['payment']?.['partner-text-3']?.[lang.slug]}}</div>
                    </div>
                </div>

                <div class="pg-row">
                    <div class="pg-logo">
                        <img src="/assets/images/partners/4.png" alt="">
                    </div>

                    <div class="pg-content">
                        <div class="pgc-sup">{{words['payment']?.['partner-sup']?.[lang.slug]}}</div>
                        <div class="pgc-title">{{words['payment']?.['partner-title-4']?.[lang.slug]}}</div>
                        <div class="pgc-text">{{words['payment']?.['partner-text-4']?.[lang.slug]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
