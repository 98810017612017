import { Component, Input } from "@angular/core";
import { ISupportClient } from "src/app/model/entities/support-client.interface";
import { ChatService } from "../../services/chat.service";
import { CookieService } from "../../services/cookie.service";
// import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
// import { Lang } from "src/app/model/entities/lang.entity";
// import { User } from "src/app/model/entities/user.entity";
// import { AppService } from "../../services/app.service";
// import { ChatService } from "../../services/chat.service";
// import { ChatRepository } from "../../services/repositories/chat.repository";

@Component({
    selector: "chat-button",
    templateUrl: "chat-button.component.html",
    styleUrls: ["chat-button.component.scss"],
})
export class ChatButtonComponent {
    public isChatOpen = false;

    constructor(
        private readonly chatService: ChatService,
    ) {}

    get chatId(): string {return this.chatService.chatId}
    get clientData(): string {return this.chatService.chatId}

    public toggleChat() {
        this.isChatOpen = !this.isChatOpen;
    }
}
