import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { Chunk } from 'src/app/model/dto/chunk';
import { IGetList } from 'src/app/model/dto/getlist.interface';
import { Message } from 'src/app/model/entities/message.entity';
import { IMessageCreate } from 'src/app/model/dto/message.create.interface';
import { IMessageMarkAsRead } from 'src/app/model/dto/message.mark-as-read.interface';

@Injectable()
export class MessageRepository {
    constructor(protected dataService: DataService) {}

    public loadChunk(part: number = 0, chunkLength: number = 20, sortBy: string = "id", sortDir: number = -1, filter: any = {}): Promise<Chunk<Message>> {
        const dto: IGetList = {from: part * chunkLength, q: chunkLength, sortBy, sortDir, filter};
        return new Promise((resolve, reject) =>
            this.dataService
                .messagesChunk(dto)
                .subscribe({
                    next: res => res.statusCode === 200 ? resolve(new Chunk(res.data.map(d => new Message().build(d)), res.elementsQuantity, res.pagesQuantity)) : reject(res.error),
                    error: err => reject(err.message)
                }));
    }

    public create(dto: IMessageCreate): Promise<Message> {
        return new Promise((resolve, reject) => this.dataService
            .messagesCreate(dto)
            .subscribe({
                next: res => res.statusCode === 201 ? resolve(new Message().build(res.data)) : reject(res.error),
                error: err => reject(err.message)
            }));
    }

    public markAsRead(dto: IMessageMarkAsRead): Promise<Message[]> {
        return new Promise((resolve, reject) => this.dataService
            .messagesMarkAsRead(dto)
            .subscribe({
                next: res => res.statusCode === 200 ? resolve(res.data) : reject(res.error),
                error: err => reject(err.message)
            }));
    }
}
