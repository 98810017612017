import { Chat } from "./chat.entity";
import { IUser } from "./user.interface";
import { Entity } from "./_entity";

export class Message extends Entity {
    public id: number;
    public chat_id: string;
    public user_id: number;
    public text: string;
    public is_read: boolean;
    public is_system: boolean;
    public created_at: Date;
    public defended: boolean;
    public chat?: Chat;
    public user?: IUser;

    get formattedCreatedAt(): string {return this.created_at ? `${this.created_at.getHours()}:${this.twoDigits(this.created_at.getMinutes())}` : "";}

    public build (o: Object): any {
        for (let field in o) {
            if (field === "created_at") {
                this[field] = o[field] ? new Date(o[field]) : null;
            // } else if (field === "user") {
            //     this[field] = new User().build(o[field]);
            } else {
                this[field] = o[field];
            }
        }

        return this;
    }
}

export interface IMessagesFilter {
    chat_id: string;
    created_at: Date;
}
