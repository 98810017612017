<div #win class="cs-win" [style.height]="height+'px'">
    <div
        #container
        class="cs-container"
        [style.left]="'calc(50% - ' + step * (width * 0.85) + 'px)'"
        [style.transform]="'translateX(-'+ width / 2 +'px)'"
        [style.width]="'calc('+width+'px * '+cards.length+' + 15px * '+(cards.length+1)+')'"
        (mousedown)="onDragStart($event);"
        (touchstart)="onDragStart($event);"
    >
        <div
            *ngFor="let card of cards; let cardIndex=index;"
            class="cs-item"
            [style.min-width]="cardIndex === step ? width+'px' : width * 0.9 + 'px'"
            [style.width]="cardIndex === step ? width+'px' : width * 0.9 + 'px'"
            [style.left]="cardIndex > step ? innerWidth < 1000 ? '7%' : '7%' : null"
            [style.right]="cardIndex < step ? innerWidth < 1000 ? '7%' : '7%' : null"
            [style.visibility]="cardIndex < step - 1 || cardIndex > step + 1 ? 'hidden' : 'visible'"
            [style.z-index]="cardIndex === step ? 999 : 10"
            [class.cs-inactive-item]="cardIndex !== step"
        >
            <the-card [card]="card" [isEditMode]="isEditMode"></the-card>
        </div>
    </div>
    <div class="cs-controls" *ngIf="cards.length > 1">
        <button (click)="onPrev()" class="smallbtn left"></button>
        <button (click)="onNext()" class="smallbtn right"></button>
    </div>
</div>
