export const cfg = {
    // serverUrl: "http://localhost:3130",
    serverUrl: "https://back.mbcinternational.pro",
    apiPath: "/api/mainsite",
    siteUrl: "https://mbcinternational.pro",
    staticUrl: "https://static.mbcinternational.pro",
    wsMessagesPath: "/socket/messages",
    maxFileSize: 5000000,
    maxImgSize: 5000000,
};
