import { Message } from "./message.entity";
import { IUser } from "./user.interface";
import { Entity } from "./_entity";

export class Chat extends Entity {
    public id: number;
    public uuid: string;
    public client_name: string;
    public created_at: Date;
    public messages: Message[];
    public unreadCount: number;
    public lastMessage: string;

    public init(): Chat {
        return this;
    }
}

export interface IChatFilter {
    user_id: number,
    created_at?: Date,
}
