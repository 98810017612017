<!-- <div class="content"> -->
<!--     <div class="multi-container"> -->
<!--         <div class="container" *ngFor="let card of cards"> -->
<!--             <card-terminal [card]="card"></card-terminal> -->
<!--         </div> -->
<!--     </div> -->
<!-- </div> -->
<div class="single-container">
    <div class="content">
        <div class="lines">
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
            <div class="l-line"></div>
        </div>
        <cards-terminal [cards]="cards"></cards-terminal>
    </div>
</div>
