import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { cfg } from "src/app/config";
import { ILang } from "src/app/model/entities/lang.interface";
import { Message } from "src/app/model/entities/message.entity";
import { IWords } from "src/app/model/entities/words.interface";
import { AppService } from "../../services/app.service";
import { ChatService } from "../../services/chat.service";

@Component({
    selector: "the-message",
    templateUrl: "message.component.html",
    styleUrls: ["message.component.scss"],
})
export class MessageComponent implements OnInit, OnDestroy {
    @Input() public x: Message;
    public loading: boolean;
    // public imgOut: SafeResourceUrl;

    constructor(
        private appService: AppService,
        private chatService: ChatService,
        // private signedImgRepository: SignedImgRepository,
        // private sanitizer: DomSanitizer,
    ) {}

    // get user(): User {return this.authService.authData.value.user;}
    get words(): IWords {return this.appService.words;}
    get lang(): ILang {return this.appService.lang;}
    get username(): string { return this.chatService.clientData.name; }

    public ngOnInit(): void {
        // console.log(':: MSG:', this.x);
    }

    public ngOnDestroy(): void {
    }
}
